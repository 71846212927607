export const personalInfo = [
    {
        id:1,
        title: 'Name : ',
        description: 'Rabia Zubair'

    },
    {
        id:2,
        title: 'Nationality : ',
        description: 'Pakistani'

    },
    {
        id:3,
        title: 'Domain : ',
        description: 'IT'

    },
    {
        id:4,
        title: 'Languages : ',
        description: 'English, Urdu'

    },
    
    {
        id:5,
        title: 'Status : ',
        description: 'Available'

    },
    {
        id:6,
        title: 'Email : ',
        description: 'Mentioned in CV'

    }
]
export const stats = [
    {
        id:1,
        no: '2+ ',
        title: 'Years of Experience'

    },
    {
        id:2,
        no: '7+ ',
        title: 'Completed Projects'

    },
    {
        id:3,
        no: '15+ ',
        title: 'Happy Customers'

    },
    
]
